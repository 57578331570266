import { Product } from "src/redux/apiTypes";
import useLocalization from 'src/services/localization/useLocalization';
import useToggles from "src/services/toggles/useToggles";
import getBundleInfo from "src/utils/product/getBundleInfo";
import getFormattedWeight from "src/utils/product/getFormattedWeight";

export const useProductTileLabels = (product: Product) => {
  const localize = useLocalization();
  const { BUNDLES_AVAILABLE } = useToggles();

  return {
    title: product.title,
    weightLabel: product.unit ? getFormattedWeight(product.unit, product.weight, product.volume, localize) : null,
    bundleLabel: (product.unit && BUNDLES_AVAILABLE) ? getBundleInfo(product.bundle, localize) : null,
  };
};
