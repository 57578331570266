import { ReactNode, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

interface Props {
  onImpressed?(): void;
  onChange?(isVisible: boolean): void;
  partialVisibility?: boolean;
  children: ReactNode
}

const Impressionable = (props: Props) => {
  const { children, onImpressed = () => {}, onChange, partialVisibility } = props;

  const [isImpressed, setIsImpressed] = useState(false);

  const handleChange = (isVisible: boolean) => {
    if (isVisible && !isImpressed) {
      onImpressed();
      setIsImpressed(true);
    }

    if (onChange) {
      onChange(isVisible);
    }
  };

  return (
    <VisibilitySensor
      onChange={handleChange}
      partialVisibility={partialVisibility}
    >
      {children}
    </VisibilitySensor>
  );
};

export default Impressionable;
